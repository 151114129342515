<template>
  <div class="page-wrapper" v-loading="loading">
    <el-row :gutter="10">
      <el-col :sm="24" :md="24" v-for="section in sections" :key="section.id">
        <!-- 关于我们 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'ABOUTUS1'"
        >
          <div slot="header" class="clearfix">
            <span>关于我们【第一段】 </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <el-form
            ref="form"
            :model="section"
            label-width="120px"
            label-position="top"
            :disabled="submitting"
          >
            <el-row :gutter="10">
              <el-col :sm="24" :md="10">
                <el-form-item label="内容">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 12, maxRows: 60 }"
                    placeholder="请输入内容"
                    v-model="section.content"
                  >
                  </el-input> </el-form-item
              ></el-col>
              <el-col :sm="24" :md="14">
                <el-form-item label="封面图">
                  <SingleImgUpload
                    class="about-us-image"
                    v-model="section.imageUrl"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'ABOUTUS2'"
        >
          <div slot="header" class="clearfix">
            <span>关于我们【第二段】 </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <el-form
            ref="form"
            :model="section"
            label-width="120px"
            label-position="top"
            :disabled="submitting"
          >
            <el-row :gutter="10">
              <el-col :sm="24" :md="10">
                <el-form-item label="内容">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 12, maxRows: 60 }"
                    placeholder="请输入内容"
                    v-model="section.content"
                  >
                  </el-input> </el-form-item
              ></el-col>
              <el-col :sm="24" :md="14">
                <el-form-item label="封面图">
                  <SingleImgUpload
                    class="about-us-image"
                    v-model="section.imageUrl"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <!-- 证书图 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'PATENT'"
        >
          <div slot="header" class="clearfix">
            <span>证书图 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <ProductImgUpload
                class="patent-item"
                tips="选择图片"
                v-model="slotProps.item.url"
                @remove="removeBanner(section, slotProps.index)"
              />
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section)"
                >添加图片</el-button
              >
            </el-button-group>
          </div>
        </el-card>

        <!-- 测试报告 -->
        <el-card
          class="box-card"
          shadow="never"
          v-if="section.title == 'TESTREPORT'"
        >
          <div slot="header" class="clearfix">
            <span>测试报告 [拖动排序] </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort
            syncSortKey
            v-model="section.images"
            class="banner-list-wrap"
          >
            <template v-slot:default="slotProps">
              <ProductImgUpload
                class="patent-item"
                tips="选择图片"
                v-model="slotProps.item.url"
                @remove="removeBanner(section, slotProps.index)"
              />
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(section)"
                >添加图片</el-button
              >
            </el-button-group>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  GetPagesById,
  GetPagesSectionsById,
  PutPagesById,
} from "@/views/pagesManager/api";
import DraggbleSort from "@/components/DraggbleSort";
import ProductImgUpload from "@/components/ProductImgUpload";
import SingleImgUpload from "@/components/SingleImgUpload";
import { ShowApiError } from "@/request/error";
export default {
  name: "HOME",
  components: { DraggbleSort, ProductImgUpload, SingleImgUpload },
  data() {
    return {
      loading: false,
      submitting: false,
      currentPageId: 7,
      sections: [],
      page: {
        name: "",
        parentId: null,
        title: "",
        subTitle: "",
        description: "",
        icon: "",
        sections: [],
        seq: null,
        hidden: null,
      },
    };
  },
  created() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.loading = true;
      GetPagesById(this.currentPageId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.page, key)) {
              this.page[key] = res.data[key];
            }
          }
          return GetPagesSectionsById(this.currentPageId);
        })
        .then((res) => {
          this.sections = res.data;
        })
        .catch((err) => {
          ShowApiError(err, "获取页面信息失败");
        })
        .finally(() => {
          this.loading = false;
        })
    },
    onSave() {
      let sectionData = this.sections.map((item) => {
        if (item.title == "ABOUTUS1") {
          item.seq = 0;
        }
        if (item.title == "ABOUTUS2") {
          item.seq = 1;
        }
        if (item.title == "PATENT") {
          item.seq = 2;
        }
        if (item.title == "TESTREPORT") {
          item.seq = 3;
        }

        return item;
      });
      let _form = {
        ...this.page,
        sections: [...sectionData],
      };
      this.submitting = true;
      PutPagesById(this.currentPageId, _form).then(() => {
        this.$message.success("保存成功");
        this.initSetting();
      })
      .catch((err) => {
          ShowApiError(err, "保存失败");
        })
      .finally(() => {
        this.submitting = false;
      })
    },
    onRefresh() {
      this.initSetting();
    },
    // 元素图
    addBanner(section) {
      let item = {
        title: "",
        subTitle: "",
        description: "",
        icon: "",
        url: "",
        seq: section.images.length + 1,
        hidden: false,
      };
      section.images.push(item);
    },
    removeBanner(section, index) {
      section.images.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-card {
  margin: 10px 0;
}
.banner-list-wrap {
  width: 100%;
  ::v-deep {
    .transition-el {
      min-height: 330px;
    }
  }
}
::v-deep {
  .custom-draggable {
    max-width: 100%;
  }
}
.patent-item {
  display: block;
  width: 200px;
  height: 300px;
  margin: 5px;
}
.about-us-image {
  width: 100%;
  max-width: 450px;
  height: 260px;
  ::v-deep {
    .img-upload-wrapper {
      height: auto;
    }
    .uploaded-img-preview {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.add-madias-item {
  text-align: center;
}
</style>
